@import './../../color';

.shop-container{
    background-image: url('./map3.jpg');
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    .content-wrapper{
        background-image: url('../../../public/ink.jpg');
        max-width: 900px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: $white;
        padding: 32px;
        @media (min-width:768px) {
            border-radius: 10px;
            box-shadow: 5px 5px 5px 5px rgba(20, 20, 29, 0.6);
        }
        p{
            text-align: center;
        }
        .btn{
            color: $white;
        }
    }
}