.data-container{
    max-width: 1200px;
    padding: 16px;
    
}
span{
    a,
    a:link,
    a:visited{
        text-decoration: underline;
    }
}