@import './../../color';

.header{
    color: $white;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    position: sticky;
    align-items: stretch;
    top: -1px;
    z-index: 10;
    width: 100%;
    .header-content-desktop, .header-content-mobile{
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: stretch;
        background-color: $dark-grey;
        padding: 16px 32px;
        flex: auto;
    }

    .header-content-desktop{
        padding: 0;
        display: none;
        @media (min-width:1200px) {
            display: flex;
        }

        .right-side{
            padding: 16px 32px 16px 16px;
        }

        .navigation{

            padding-left: 16px;
            
            li{
                transition: background-color 0.5s ease;
                cursor: pointer;
                &:hover{
                    background-color: $grey;
                    border-bottom: 2px solid $red;
                }
                
            }
        }
    }

    .header-content-mobile, 
    .header-flyout-mobile{
        display: flex;
        @media (min-width:1200px) {
            display: none;
        }
    }

    .header-content-mobile{
        position: relative;
    }

    @media (min-width: 1200px) {
        flex-direction: row;
    }

    .right-side{
        display: flex;
        justify-content: flex-end;
    }

    .language-changer, .burger-icon{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($color: #000000, $alpha: 0);
        outline:none;
        border: none;
        background-color: transparent;
        background-repeat: no-repeat;
        &:hover{
            cursor: pointer;
        }
        img{
            height: 24px;
        }
    }
    
    .navigation, .right-side{
        display: flex;
        gap: 32px;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
        li{
            height: 100%;
            padding: 0 16px;
            display: flex;
            list-style: none;
            justify-content: center;
            align-items: center;
            
            a{
                color: $white;
                height: 100%;
                display: flex;
                align-items: center;
            }
            
            
        }
    }
    .header-name{
        font-size: 24px;
    }
    .links{
        display: flex;
        gap: 16px;
        li{
            padding: 0;
        }
        .icon{
            img{
                width: 32px;
            }
        }
    }

    @media (max-width:1199px) {
        .header-flyout-mobile{
            position: absolute;
            top: 50px;
            transform: translateX(-100%);
            width: 350px;
            background-color: $dark-grey;
            height: calc(100vh - 42px);
            display: flex;
            flex-direction: column;
            margin-top: -2px;
            gap: 32px;
            >.navigation, >.links{
                align-items: flex-start;
                list-style: none;
                display: flex;
                padding: 0;
                gap: 0;
                >li{
                    padding: 16px 32px;
                }
            }
    
            >.navigation{
                flex-direction: column;
                justify-content: center;
                li{
                    height: auto;
                }
            }
    
            >.links{
                flex-direction: row;
                justify-content: flex-start;
                flex-wrap: wrap;
            }

            &[flyoutAnim='1']{
                animation: open 1s forwards;
            }

            &[flyoutAnim='0']{
                animation: close 1s forwards;
            }

            @keyframes open {
                0% {
                    transform: translateX(-100%);
                }
                100% {
                    transform: translateX(0);
                }
            }

            @keyframes close {
                0% {
                    transform: translateX(0);
                }
                100% {
                    transform: translateX(-100%);
                }
            }
        }
    }


    
}