@import './../../color';
@import './../../font-size';


footer{
    background-color: $dark-grey;
    color: $white;
    font-size: $font-size-s;
    
    
    .footer-first-row, .footer-second-row{
        
        div{
            padding: 0 16px;
        }

        .content{
            padding: 16px 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            
                max-width: 100%;
                    @media (min-width: 1200px) {
                        max-width: 1200px;
                    }
                
                margin: auto;
            
        }

    }

    ul{
        list-style: none;
        display: flex;
    }

    .footer-second-row{
        background-color: $dark-grey;
        padding: 16px 0;
        a, ul{
            padding: 0 16px;
        }
    }
}
