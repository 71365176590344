@import './../../color';

.wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    
    div, img{
        max-width: 100%;

        @media (min-width: 768px) {
            max-width: 584px;
        }
    }

    .content{
        padding: 32px;
        @media (min-width: 1200px) {
            padding: 0;
        }
    }

    .btn{
        margin: 16px 0;
    }

    .link-wrapper{
        width: fit-content;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}