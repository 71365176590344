@import './../../color';

* { box-sizing: border-box; }
$duration: 30s;

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;

  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.anouncement-wrapper {
  position: absolute;
  width: 100vw;
  overflow: hidden;
  height: 4rem;
  background-color: $grey;

  .announcement {

    display: inline-block;
    height: 4rem;
    padding-left: 100%;
    line-height: 4rem;  
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    font-weight: 900;
    font-size: 2em;
    text-transform: uppercase;
    white-space: nowrap;
    color: $red;
    

    -webkit-animation-iteration-count: infinite; 
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
   -webkit-animation-name: ticker;
           animation-name: ticker;
    -webkit-animation-duration: $duration;
            animation-duration: $duration;

    .announcement-message {

      display: inline-block;

      padding: 0 2rem;
      color: $red;

      span {
        color: $white;
      }
    }


  }

}