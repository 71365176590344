@import './../../color';
@import './../../font-size';

.szh-accordion{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 32px;
    
   
    button{
        width: 100%;
        height: 3.5rem;
        text-align: left;
        font-size: $font-size-m;
        background-color: $dark-grey;
        color: $white;
        border: none;
        cursor: pointer;
        padding: 0 16px;
    }
    &__item-content{
        background-color: $grey;
        color: $white;
        padding: 16px 32px;
        font-size: $font-size-m;
    }
}