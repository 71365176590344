@import './../../color';

.gallery-list{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    
    @media (min-width: 900px) {
        justify-content: flex-start;
    }

    .gallery-element{
        width: 270px;
        overflow: hidden;
        aspect-ratio: 3 / 4;
        img{
            object-fit: cover
        }

        &:hover{
            img{
                transform: scale(1.2);
                cursor: pointer;
            }
        }
        
        img{
            width: 100%;
            height: 100%;
            transition: transform .5s ease;
        }
    }

    img{
        max-width: 90vw;
    }
}




.modal{
    background-color: rgba($color: #000000, $alpha: .8);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; 
    overflow-y: scroll;
    inset: 0; 

    .modal-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        img{
            border-radius: 10px;
        }
        h3,p{
            margin: 0;
            color: $white;
        }
    }
    
    .modal-wrapper{
        display: flex;
    }

    .modal-content, img{
        max-height: 70vh;
        max-width: 300px;
        @media (min-width: 768px) {
            max-width: 500px;
        }

        @media (min-width: 1200px) {
            max-width: 700px;
        }
    }
}