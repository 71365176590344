@import './../../color';

.hero-container{
    width: 100%;
    height: 50vh;
    box-sizing: border-box;
    background-image: url(../../../public/header.jpeg);
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 200%;
    margin-top: 4rem;
    // background-position-y: -30vh;
    @media (min-width: 768px) {
        background-size: cover;
        height: 80vh;
        // background-position-y: -50vh;
    }
    @media (min-width:1200px) {
        // background-position-y: -70vh;
    }
    .headline{
        background-color: $dark-grey;
        color: $white;
        padding: 8px 32px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        max-width: 100%;
        font-size: 5vw;
        
       
        img{
            height: 1.7em;
            fill: #fff;
        }

        @media (min-width: 500px) {
           
            //font-size: 1.5em;
        }

        @media (min-width: 900px) {
            
            font-size: 3em;
            img{
                height: 1.7em;
            }
        }
    }

    // .headline2{
    //     background-color: $dark-grey;
    //     color: $white;
    //     padding: 8px 32px;
    //     border-radius: 15px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     gap: 16px;
    //     max-width: 100%;
    //     height: 200px;
    //     font-size: 3vw;
    //     margin-left: 200px;
    //     margin-bottom: 100px;

    //     img{
    //         height: 70%;
    //         fill: #fff;
    //     }
    // }
}