@import './color';
@import './font-size';

@font-face {
    font-family: 'Hina-mincho';
    src: url('./fonts/HinaMincho-Regular.ttf') format('truetype');   
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html{
    scroll-behavior: smooth;
    
}

.App{
    box-sizing: border-box;
    background-color: $white;
    font-size: $font-size-m;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    &.japanese{
        font-family: "Hina-mincho", serif;
    }
}

.container{
    max-width: 1200px;
    margin: 128px auto;
    @media (min-width: 760px) {
       
    }
}

a:link, a:visited{
    color: inherit;
    text-decoration: none;
}

h2{
    font-size: 36px;
}

a{
    &.inline-text{
        color: $dark-red;
        
        &:hover{
            border-bottom: solid 2px $dark-red;
        }
        &:link, &:visited{
            color: $dark-red;
            text-decoration: none;
        }
    }

    &.external-link{
        flex: 0 1 auto; 
        color: $dark-red;
        span{
            border-bottom: solid 2px $dark-red;
        }
        
        &::after{
            content: ' ';
            display: inline-block;
            border-bottom: 2px solid $dark-red;
            border-right: 2px solid $dark-red;
            height: 8px;
            width: 8px;
            transform: rotate(-45deg);
        }

        a:link, a:visited{
            color: $dark-red;
            text-decoration: none;
        }
    }
}

.arrow-right, .arrow-left{
    outline:none;
    border: none;
    background-color: transparent;
    background-repeat: no-repeat;
    &:hover{
        cursor: pointer;
    }
    &::after{
        content: ' ';
        display: inline-block;
        border-bottom: 3px solid $white;
        border-right: 3px solid $white;
        
        height: 16px;
        width: 16px;
        background-color: rgba($color: #000000, $alpha: 0);
    }
}

.arrow-right{
    transform: rotate(-45deg);
}

.arrow-left{
    transform: rotate(135deg);
}

.toad{
    position: fixed;
    bottom: 0;
    right: 0;
    width: 128px;
    @media (min-width: 764px) {
        width: 200px;
    }
    @media (min-width: 1200px) {
        width: 300px;
    }
    
}

ul, h1, h2{
    margin: 0;
}

ul{
    padding: 0;
}

.anchor::before{
    content: '';
    display: block;
    height:      200px;
    margin-top: -200px;
    visibility: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn{
    padding: 8px 16px;
    font-size: 1.2rem;
    color: $white;
    text-align: center;
    min-width: 96px;
    border: 2px solid $dark-red;
    background-color: $red;
    transition:background-color .2s ease-in;
    border-radius: 5px;
    
    &:hover{
        cursor: pointer;
        background-color: $dark-red;
    }
}

a, p, h1, h2, h3, h4{
    &::selection{
        background: $red;
        color: $white;
    }
}
