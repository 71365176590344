@import './../../color';

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact-wrapper{
    
    padding: 32px;
    width: 100%;
    box-sizing: border-box;
    @media (min-width: 768px) {
        width: 500px;
    }
    

    form{
        display: flex;
        flex-direction: column;
        
        gap: 8px;
        
        

        

        input[type=text],
        input[type=email],
        input[type=tel],
        textarea{
            font-size: large;
            padding: 8px;
            border: 2px solid $grey;
            width: calc(100% - 15px);
            border-radius: 5px;
        }
        textarea{
            height: 12em;
        }
        
        .submit-element{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        p{
            margin: 0;
        }
    }

    .success-message{
         width: 100%;
         border-radius: 5px;
         padding: 16px 8px;
         background-color: $success;   
    }

    .mailAdress, .mailAdress:link, .mailAdress:visited{
        color: rgb(182, 2, 2);
        text-decoration: underline;
    }
}